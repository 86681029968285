import {
  Barre,
  Single,
  FretboardString,
} from '@roots-productions/fretboard'

interface MaybeChordWithFingerPlacement {
  instrument?: FretboardString[]
  singles?: Single[]
  barres?: Barre[]
  muted_strings?: number[]
  string_note_strings?: string[]
}

interface ChordWithFingerPlacement {
  instrument: FretboardString[]
  singles: Single[]
  barres: Barre[]
  muted_strings: number[]
  string_note_strings: string[]
}

const createChordWithFingerPlacement = () => (
  chordWithFingerPlacement: MaybeChordWithFingerPlacement = {}
): ChordWithFingerPlacement => {
  const {
    singles = [],
    barres = [],
    instrument = [],
    muted_strings = [],
    string_note_strings = [],
  } = chordWithFingerPlacement

  return {
    singles,
    barres,
    instrument,
    muted_strings,
    string_note_strings,
  }
}

export { createChordWithFingerPlacement }
export type {
  MaybeChordWithFingerPlacement,
  ChordWithFingerPlacement,
}
