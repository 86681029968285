import React, { FC, ReactNode } from 'react'
import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react'

interface ExpandableSectionProps {
  children: ReactNode
  title: ReactNode
}

const ExpandableSection: FC<ExpandableSectionProps> = ({
  children,
  title,
}) => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box flex="1" textAlign="left" fontWeight="500">
          {title}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>{children}</AccordionPanel>
    </AccordionItem>
  )
}

export { ExpandableSection }
export type { ExpandableSectionProps }
