import { chordFromTokenizedChord } from './chord-from-tokenized-chord'
import { noteListFromChord } from './note-list-from-chord'
import { noteInformationFromChord } from './note-information-from-chord'
import { textGenerator } from './text-generator'
import { chordVariationsFromChord } from './chord-variations-from-chord'
import { chordVariationsTextsFromChord } from './chord-variations-texts-from-chord'
import { isAssumedChordHalfStepAlignmentSharp } from './is-assumed-chord-half-step-alignment-sharp'
import { updateChord } from './update-chord'
type ChordStateHandlerModule = typeof import('@roots-productions/chord_state_handler/esm/chord_state_handler')

/* 
  Next is note-information. Use half step assumer.
*/

const storedModule: {
  isStored: boolean
  module: ChordStateHandlerModule | null
} = {
  isStored: false,
  module: null,
}

const _chordStateHandler = async () => {
  if (storedModule.isStored) {
    return storedModule.module as ChordStateHandlerModule
  }

  const chordStateHandlerModule: ChordStateHandlerModule = await import(
    '@roots-productions/chord_state_handler'
  )

  storedModule.module = chordStateHandlerModule
  storedModule.isStored = true

  return storedModule.module as ChordStateHandlerModule
}

const chordStateHandler = async () => {
  const chordStateHandlerModule = await _chordStateHandler()

  return {
    chordFromTokenizedChord: chordFromTokenizedChord(
      chordStateHandlerModule
    ),
    noteListFromChord: noteListFromChord(chordStateHandlerModule),
    noteInformationFromChord: noteInformationFromChord(
      chordStateHandlerModule
    ),
    textGenerator: textGenerator(chordStateHandlerModule),
    chordVariationsFromChord: chordVariationsFromChord(
      chordStateHandlerModule
    ),
    chordVariationsTextsFromChord: chordVariationsTextsFromChord(
      chordStateHandlerModule
    ),
    isAssumedChordHalfStepAlignmentSharp: isAssumedChordHalfStepAlignmentSharp(
      chordStateHandlerModule
    ),
    updateChord: updateChord(chordStateHandlerModule),
  }
}

export { chordStateHandler }
export type { ChordStateHandlerModule }
