import React, { FC } from 'react'

interface NutProps {
  color: string
  height: number
  svgWidth: number
  strokeWidth: number
  xStart: number
  padding: number
  topMargin: number
}

const Nut: FC<NutProps> = ({
  color,
  height,
  svgWidth,
  strokeWidth,
  xStart,
  padding,
  topMargin,
}) => {
  return (
    <rect
      x={xStart - padding / 2}
      y={2 + topMargin}
      width={svgWidth + padding}
      height={height}
      stroke={color}
      strokeWidth={strokeWidth}
      // TODO: color should be dynamic
      fill="white"
    />
  )
}

export { Nut }
