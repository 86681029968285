import { FretboardConf } from './fretboard-conf'
import type { ChordInformation } from './chord-information'
import {
  calcFretCount,
  calcHeight,
  calcWidth,
  createMargins,
  firstAndLastFret,
  fretYPositions,
  stringXPosition,
} from './common'

const useFretboard = (
  fretboardConf: FretboardConf,
  chordInformation: ChordInformation
) => {
  const {
    fretColor,
    strings,
    stringDistance,
    fretDistance,
    minFretCount,
    nutHeight,
    nutColor,
    circleRadius,
  } = fretboardConf

  const {
    barres = [],
    circles = [],
    // TODO: Render the below, if present
    textAbove = [],
    textBelow = [],
  } = chordInformation

  const leftPadding = 1
  const textSize = 8
  const extraStringLength = 4

  const [firstFret, lastFret] = firstAndLastFret(circles, barres)

  const isNutVisible = firstFret === 1 || minFretCount >= lastFret

  const offsetToFirstFret = isNutVisible ? 0 : firstFret - 1

  const fretCount = calcFretCount(minFretCount, firstFret, lastFret)

  const margin = createMargins(
    textSize,
    circleRadius + 18,
    textSize + extraStringLength,
    circleRadius + 18
  )

  const svgHeight = calcHeight({
    nutHeight,
    fretCount,
    fretDistance,
    verticalMargin: margin.vertical,
    extraStringLength,
  })
  const svgWidth = calcWidth(strings, stringDistance, leftPadding * 2)

  const _fretYPositions = fretYPositions(
    // Added margin.top
    nutHeight + margin.top,
    fretDistance,
    fretCount
  )

  const stringXPositions = strings.map((_, i) =>
    stringXPosition(leftPadding + margin.left, stringDistance, i)
  )

  return {
    svgWidth,
    svgHeight,
    margin,
    fretYPositions: _fretYPositions,
    fretColor,
    leftPadding,
    stringXPositions,
    strings,
    extraStringLength,
    isNutVisible,
    fretDistance,
    firstFret,
    circleRadius,
    nutColor,
    nutHeight,
    circles,
    offsetToFirstFret,
    barres,
    textAbove,
    textBelow,
  }
}

export { useFretboard }
