import React, { FC } from 'react'

interface FretsProps {
  xStart: number
  color: string
  svgWidth: number
  yPositions: number[]
  padding: number
}

const Frets: FC<FretsProps> = ({
  xStart,
  color,
  svgWidth,
  yPositions,
  padding,
}) => {
  const xEnd = svgWidth + xStart - padding

  // const frets = Array.from({ length: amount + 1 }, (_, i) =>
  //   i === 0 ? startingY : i * distanceBetween + startingY
  // )
  return (
    <>
      {yPositions.map((y) => (
        <path
          d={`M${xStart} ${y}H${xEnd}`}
          stroke={color}
          strokeWidth="2"
          key={y}
        />
      ))}
    </>
  )
}

export { Frets }
