import React, { FC } from 'react'

interface TextProps {
  xPosition?: number
  yPosition?: number
  size?: number
  color?: string
  children?: string
}

const Text: FC<TextProps> = ({
  xPosition = 0,
  yPosition = 0,
  size = 8,
  color = 'rgb(0,0,0)',
  children = '',
}) => {
  return (
    <text
      x={xPosition}
      y={yPosition}
      style={{
        font: `${size}px sans-serif`,
        fill: color,
        textAnchor: 'middle',
        dominantBaseline: 'middle',
      }}
    >
      {children}
    </text>
  )
}

export { Text }
