import React, { FC } from 'react'
import { FretboardConf } from './fretboard-conf'
import type { ChordInformation } from './chord-information'
import {
  Barres,
  Circles,
  FretboardStringList,
  Frets,
  Nut,
  SvgDropshadow,
  FretNumber,
  Svg,
  TextBelow,
  TextAbove,
} from './components'
import { useFretboard } from './use-fretboard'

interface FretboardDiagramProps {
  fretboardConf: FretboardConf
  chordInformation: ChordInformation
  height?: string | number
  width?: string | number
}

const FretboardDiagram: FC<FretboardDiagramProps> = ({
  fretboardConf,
  chordInformation,
  height,
  width,
}) => {
  const {
    svgWidth,
    svgHeight,
    margin,
    fretYPositions,
    fretColor,
    leftPadding,
    stringXPositions,
    strings,
    extraStringLength,
    isNutVisible,
    fretDistance,
    firstFret,
    circleRadius,
    nutColor,
    nutHeight,
    circles,
    offsetToFirstFret,
    barres,
    textAbove,
    textBelow,
  } = useFretboard(fretboardConf, chordInformation)

  const dropShadowId = 'drop-shadow'

  return (
    <Svg
      width={width ?? svgWidth + margin.horizontal}
      height={height ?? svgHeight}
      viewBoxWidth={svgWidth + margin.horizontal}
      viewBoxHeight={svgHeight}
    >
      <SvgDropshadow id={dropShadowId} />
      <Frets
        yPositions={fretYPositions}
        color={fretColor}
        xStart={leftPadding + margin.left}
        svgWidth={svgWidth}
        padding={leftPadding * 2}
      />
      <FretboardStringList
        xPositions={stringXPositions}
        strings={strings}
        fretYPositions={fretYPositions}
        extraStringLength={extraStringLength}
      />
      {!isNutVisible ? (
        <FretNumber
          firstFretY={fretYPositions[0] ?? 0}
          firstStringX={stringXPositions[0]}
          fretDistance={fretDistance}
          number={isNutVisible ? 1 : firstFret}
          circleRadius={circleRadius}
          color={nutColor}
        />
      ) : null}
      {isNutVisible ? (
        <Nut
          color={nutColor}
          height={nutHeight}
          strokeWidth={3}
          svgWidth={svgWidth}
          xStart={margin.left}
          padding={leftPadding * 2}
          topMargin={margin.top}
        />
      ) : null}
      <Circles
        circles={circles}
        fretDistance={fretDistance}
        fretYPositions={fretYPositions}
        stringXPositions={stringXPositions}
        radius={circleRadius}
        offsetToFirstFret={offsetToFirstFret}
        filterId={dropShadowId}
      />
      <Barres
        barres={barres}
        fretDistance={fretDistance}
        fretYPositions={fretYPositions}
        stringXPositions={stringXPositions}
        radius={circleRadius}
        offsetToFirstFret={offsetToFirstFret}
        filterId={dropShadowId}
      />
      <TextAbove
        stringXPositions={stringXPositions}
        texts={textAbove}
      />
      <TextBelow
        fretYPositions={fretYPositions}
        stringXPositions={stringXPositions}
        texts={textBelow}
        extraStringLength={extraStringLength}
      />
    </Svg>
  )
}

export { FretboardDiagram }
export type { FretboardDiagramProps }
