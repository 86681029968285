import React, { FC } from 'react'
import { FretboardStringOptions } from '../../fretboard-conf'
import { FretboardString } from './fretboard-string'
import { lastOr, firstOr } from '../../common'

interface FretboardStringListProps {
  strings: FretboardStringOptions[]
  xPositions: number[]
  fretYPositions: number[]
  extraStringLength: number
}

const FretboardStringList: FC<FretboardStringListProps> = ({
  strings,
  xPositions,
  fretYPositions,
  extraStringLength,
}) => {
  const lastFretY = lastOr(0)(fretYPositions)
  const stringsY = firstOr(0)(fretYPositions) - 4

  return (
    <>
      {strings.map(
        ({ strokeWidth = 1, color = 'hsl(0, 50%, 15%)' }, i) => (
          <FretboardString
            y={stringsY}
            x={xPositions[i] ?? 0}
            yEnd={lastFretY + extraStringLength}
            color={color}
            key={i}
            strokeWidth={strokeWidth}
          />
        ),
      )}
    </>
  )
}

export { FretboardStringList }
