import React, { FC } from 'react'
import { Text } from '../text'

interface CircleProps {
  xPosition?: number
  yPosition?: number
  radius?: number
  filter?: string
  color?: string
  text?: string
  textBackground?: string
  textColor?: string
}

const Circle: FC<CircleProps> = ({
  xPosition = 0,
  yPosition = 0,
  radius = 8,
  filter = '',
  color = 'rgb(0,0,0)',
  text = '',
  textBackground = 'rgb(255,255,255)',
  textColor = 'rgb(0,0,0)',
}) => {
  const borderWidth = 2

  return (
    <>
      <circle
        cx={xPosition}
        cy={yPosition}
        r={radius}
        fill={color}
        filter={filter}
      />
      {text && (
        <>
          <circle
            cx={xPosition}
            cy={yPosition}
            r={radius - borderWidth}
            fill={textBackground}
          />
          <Text
            xPosition={xPosition}
            yPosition={yPosition}
            size={radius}
            color={textColor}
          >
            {text}
          </Text>
        </>
      )}
    </>
  )
}

export { Circle }
