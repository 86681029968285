import React, { FC } from 'react'
import { Flex, Spacer, VStack } from '@chakra-ui/react'
import { BlackKey } from './black-key'
import { WhiteKey } from './white-key'
import { NoteAsNumber, NoteAsString } from '@types'

interface PianoNotePickerProps {
  onClick?: (noteText: NoteAsString, noteNumber: NoteAsNumber) => void
}

const noteObject = (
  noteText: NoteAsString,
  noteNumber: NoteAsNumber
) => ({
  noteText,
  noteNumber,
})

const _nO = noteObject

const notes = [
  _nO('C', 0),
  _nO('D', 2),
  _nO('E', 4),
  _nO('F', 5),
  _nO('G', 7),
  _nO('A', 9),
  _nO('B', 11),
]

const PianoNotePicker: FC<PianoNotePickerProps> = ({
  onClick = () => {},
}) => {
  const halfStepSymbol = '♯ / ♭'

  return (
    <VStack w="100%" spacing="1">
      <Flex w="100%">
        <Spacer flex="1" />
        <BlackKey onClick={() => onClick('C#', 1)}>
          {halfStepSymbol}
        </BlackKey>
        <BlackKey onClick={() => onClick('D#', 3)}>
          {halfStepSymbol}
        </BlackKey>
        <Spacer flex="2" />
        <BlackKey onClick={() => onClick('F#', 6)}>
          {halfStepSymbol}
        </BlackKey>
        <BlackKey onClick={() => onClick('G#', 8)}>
          {halfStepSymbol}
        </BlackKey>
        <BlackKey onClick={() => onClick('A#', 10)}>
          {halfStepSymbol}
        </BlackKey>
        <Spacer flex="1" />
      </Flex>
      <Flex w="100%">
        {notes.map((n) => (
          <WhiteKey
            key={n.noteText}
            onClick={() => onClick(n.noteText, n.noteNumber)}
          >
            {n.noteText}
          </WhiteKey>
        ))}
      </Flex>
    </VStack>
  )
}

export { PianoNotePicker }
