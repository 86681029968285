const noteFunctionMap = new Map([
  ['bass', 'Bass'],
  ['11', '11th'],
  ['5', '5th'],
  ['maj7', 'Major 7th'],
  ['ma3', 'Major 3rd'],
  ['-11', 'Diminished 11th'],
  ['-5', 'Diminished 5th'],
  ['-9', 'Diminished 9th'],
  ['-13', 'Diminished 13th'],
  ['-mi3', 'Minor 3rd'],
  ['9', '9th'],
  ['+11', 'Augmented 11th'],
  ['+5', 'Augmented 5th'],
  ['+9', 'Augmented 9th'],
  ['+13', 'Augmented 13th'],
  ['root', 'Root'],
  ['7', 'Minor 7th'],
  ['6/13', '6th / 13th'],
  ['sus4', 'Suspended 4th'],
  ['sus2', 'Suspended 2nd'],
])

const noteFunctionToLongText = (shortText: string): string =>
  noteFunctionMap.get(shortText) ?? ''

export { noteFunctionToLongText }
