import React from 'react'
import { RootRouter } from '@components/router'
import { NavBar } from '@components/navbar'

const App = () => {
  return (
    <>
      <NavBar />
      <RootRouter />
    </>
  )
}

export { App }
