import { ChordStateHandlerModule } from '../chord-state-handler-module'
import { Chord, createGeneratorData } from '../model'

const textGenerator = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chord: Chord, isSharp: boolean) => {
  const { text_generator } = chordStateHandlerModule

  return createGeneratorData(chordStateHandlerModule)(
    JSON.parse(text_generator(JSON.stringify(chord), isSharp))
  )
}

export { textGenerator }
