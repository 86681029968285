import { Chord, createBareChordNote } from '../model'
import { ChordStateHandlerModule } from '../chord-state-handler-module'

const createBareChordNotes = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (data: any[]) => {
  return data.map(createBareChordNote(chordStateHandlerModule))
}

const noteListFromChord = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chord: Chord) => {
  const { note_list_from_chord } = chordStateHandlerModule

  return createBareChordNotes(chordStateHandlerModule)(
    JSON.parse(note_list_from_chord(JSON.stringify(chord)))
  )
}

export { noteListFromChord }
