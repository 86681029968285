interface MaybeToken {
  text?: string
  offset?: number
}

interface Token {
  text: string
  offset: number
}

interface MaybeTokenizedChord {
  errors?: Token[]
  tokens?: Token[]
  text?: string
}

interface TokenizedChord {
  errors: Token[]
  tokens: Token[]
  text: string
}

const createToken = ({
  text = '',
  offset = 0,
}: MaybeToken): Token => {
  return {
    text,
    offset,
  }
}

const createTokenizedChord = ({
  errors = [],
  tokens = [],
  text = '',
}: MaybeTokenizedChord): TokenizedChord => {
  return {
    errors: errors.map(createToken),
    tokens: tokens.map(createToken),
    text,
  }
}

export type { Token, TokenizedChord }
export { createTokenizedChord }
