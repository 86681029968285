import { FretboardBarre, FretboardCircle } from '../components'
import { fretNumbersSorted } from './fret-numbers-sorted'
import { takeFirstAndLastOrDefault } from './take-first-and-last-or-default'

const firstAndLastFret = (
  circles: FretboardCircle[],
  barres: FretboardBarre[]
) => {
  return takeFirstAndLastOrDefault(
    fretNumbersSorted(circles, barres),
    1,
    3
  )
}

export { firstAndLastFret }
