import React, { FC } from 'react'
import { Text } from '../text'

interface BarreProps {
  xPositionStart?: number
  xPositionEnd?: number
  yPosition?: number
  radius?: number
  filter?: string
  color?: string
  text?: string
  textBackground?: string
  textColor?: string
}

const Barre: FC<BarreProps> = ({
  xPositionStart = 0,
  xPositionEnd = 0,
  yPosition = 0,
  radius = 8,
  filter = '',
  color = 'rgb(0,0,0)',
  text = '',
  textBackground = 'rgb(255,255,255)',
  textColor = 'rgb(0,0,0)',
}) => {
  const borderWidth = 2

  const width = xPositionEnd - xPositionStart

  return (
    <>
      <path
        d={`M${xPositionStart} ${yPosition}H${xPositionEnd}`}
        stroke={color}
        strokeWidth={radius * 2}
        strokeLinecap="round"
        filter={filter}
      />
      {text && (
        <>
          <path
            d={`M${xPositionStart} ${yPosition}H${xPositionEnd}`}
            stroke={textBackground}
            strokeWidth={radius * 2 - borderWidth * 2}
            strokeLinecap="round"
          />

          <Text
            xPosition={xPositionStart + width / 2}
            yPosition={yPosition}
            size={radius}
            color={textColor}
          >
            {text}
          </Text>
        </>
      )}
    </>
  )
}

export { Barre }
