const fretYPositions = (
  startingY: number,
  fretDistance: number,
  fretCount: number
) => {
  return [
    startingY,
    ...Array.from(
      { length: fretCount },
      (_, i) => (i + 1) * fretDistance + startingY
    ),
  ]
}

export { fretYPositions }
