import { ChordStateHandlerModule } from '../chord-state-handler-module'
import { Chord, createChordNote } from '../model'

const createChordNotes = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (data: any[]) => {
  return data.map(createChordNote(chordStateHandlerModule))
}

const noteInformationFromChord = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chord: Chord, isSharp: boolean) => {
  const { note_information_from_chord } = chordStateHandlerModule

  return createChordNotes(chordStateHandlerModule)(
    JSON.parse(
      note_information_from_chord(JSON.stringify(chord), isSharp)
    )
  )
}

export { noteInformationFromChord }
