import React from 'react'

interface FretboardStringProps {
  color?: string
  strokeWidth?: number
  x: number
  y: number
  yEnd: number
}

const FretboardString = ({
  color = 'hsl(0, 50%, 15%)',
  strokeWidth = 1,
  x,
  y,
  yEnd,
}: FretboardStringProps) => {
  return (
    <path
      d={`M${x} ${y}V${yEnd}`}
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
    />
  )
}

export { FretboardString }
