import { createFretboardConf } from './create-fretboard-conf'

const stringColor = 'hsl(0,0%,15%)'
const fretColor = 'hsl(0,0%,60%)'

const standardUkulele = createFretboardConf({
  fretColor,
  stringColor,
  strings: [
    {
      strokeWidth: 1.6,
    },
    {
      strokeWidth: 2.8,
    },
    {
      strokeWidth: 2.2,
    },
    {
      strokeWidth: 1,
    },
  ],
  fretDistance: 30,
  stringDistance: 18,
  circleRadius: 8,
  minFretCount: 3,
})

export { standardUkulele }
