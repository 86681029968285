import { FretboardBarre, FretboardCircle } from '../components'

const fretNumbersSorted = (
  circles: FretboardCircle[],
  barres: FretboardBarre[]
) => {
  const frets = [
    ...barres.map(({ fret }) => fret),
    ...circles.map(({ fret }) => fret),
  ]

  frets.sort((a, b) => a - b)

  return frets
}

export { fretNumbersSorted }
