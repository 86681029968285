import React, { FC, useState } from 'react'
import { Center, Box, VStack } from '@chakra-ui/react'
import { ChordWithFingerPlacement } from '@src/chord-logic/fretboard/model'
import { Switcher } from './switcher'
import { FretboardChord } from '../fretboard-chord'

interface FretboardSectionProps {
  fingerPlacement?: ChordWithFingerPlacement
}

const FretboardSection: FC<FretboardSectionProps> = ({
  fingerPlacement,
}) => {
  const [showNotes, setShowNotes] = useState(true)
  const [showFingers, setShowFingers] = useState(true)

  return (
    <Box w="100%">
      <Center h={200} mb={2}>
        <FretboardChord
          showFingers={showFingers}
          showNotes={showNotes}
          fingerPlacement={fingerPlacement}
        />
      </Center>
      <VStack spacing={2}>
        <Switcher
          text="Show notes"
          isChecked={showNotes}
          onChange={() => setShowNotes(!showNotes)}
        />
        <Switcher
          text="Show fingers"
          isChecked={showFingers}
          onChange={() => setShowFingers(!showFingers)}
        />
      </VStack>
    </Box>
  )
}

export { FretboardSection }
