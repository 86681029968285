import React, { FC } from 'react'
import {
  FretboardDiagram,
  standardGuitar,
  FretboardBarre,
  FretboardCircle,
} from '@pure-components/fretboard-diagram'
import { ChordWithFingerPlacement } from '@src/chord-logic/fretboard/model'

interface FretboardChordProps {
  fingerPlacement?: ChordWithFingerPlacement
  showNotes?: boolean
  showFingers?: boolean
}

const FretboardChord: FC<FretboardChordProps> = ({
  fingerPlacement,
  showFingers = true,
  showNotes = true,
}) => {
  const { barres = [], singles = [], string_note_strings = [] } =
    fingerPlacement ?? {}

  const circles: FretboardCircle[] = singles.map(
    ({ finger, fret, string }) => ({
      fret,
      string,
      color: '#3871C7',
      text: showFingers ? `${finger}`[0] : '',
    })
  )

  const _barres: FretboardBarre[] = barres.map(
    ({ finger, fret, from_string, to_string }) => ({
      fret,
      strings: [from_string, to_string],
      color: '#3871C7',
      text: showFingers ? `${finger}`[0] : '',
    })
  )

  return (
    <FretboardDiagram
      height="100%"
      width="100%"
      fretboardConf={standardGuitar}
      chordInformation={{
        barres: _barres,
        circles: circles,
        textAbove: ['E', 'A', 'D', 'G', 'B', 'E'],
        textBelow: showNotes ? string_note_strings : [],
      }}
    />
  )
}

export { FretboardChord }
