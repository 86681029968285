const calcWidth = (
  strings: any[] = [],
  stringDistance: number = 19.8,
  stringPadding: number = 6
) => {
  const normalizedAmount =
    strings.length - 1 < 0 ? 0 : strings.length - 1

  return stringDistance * normalizedAmount + stringPadding
}

export { calcWidth }
