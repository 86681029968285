import React, { FC } from 'react'
import { Text } from '../text'

interface TextAboveProps {
  stringXPositions: number[]
  texts: string[]
  size?: number
}

const TextAbove: FC<TextAboveProps> = ({
  stringXPositions,
  texts,
  size = 8,
}) => {
  return (
    <>
      {texts.map((text, i) => {
        const xPosition = stringXPositions[i] ?? 0

        return (
          <Text
            key={`${xPosition}${text}`}
            yPosition={size / 2}
            xPosition={xPosition}
            size={size}
          >
            {text}
          </Text>
        )
      })}
    </>
  )
}

export { TextAbove }
