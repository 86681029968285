import React, { FC } from 'react'
import { css } from '@emotion/react'
import { Text, Flex, Spacer, Button } from '@chakra-ui/react'
import { DeleteIcon } from '@chakra-ui/icons'
import { ChordNote } from '@chord-logic/chord-state-handler/model'
import { noteFunctionToLongText } from '@common'

interface NoteRowProps {
  note: ChordNote
  onClick: (noteText: string) => void
}

const NoteRow: FC<NoteRowProps> = ({ note, onClick }) => {
  const style = css({
    '.chord-property': {
      '&~.chord-property::before': {
        content: '", "',
      },
    },
    '.note': {
      fontWeight: 600,
    },
  })

  const { note_text = '', note_function_readable = [] } = note

  return (
    <Flex w="100%" align="center">
      <Text css={style}>
        <span className="note">{note_text}</span>
        {': '}
        {note_function_readable.map((func) => (
          <span className="chord-property" key={func}>
            {noteFunctionToLongText(func)}
          </span>
        ))}
      </Text>
      <Spacer />
      <Button onClick={() => onClick(note_text)}>
        <DeleteIcon />
      </Button>
    </Flex>
  )
}

export { NoteRow }
