const createMargins = (
  top: number,
  right: number,
  bottom: number,
  left: number
) => {
  return {
    top,
    right,
    bottom,
    left,
    vertical: top + bottom,
    horizontal: left + right,
  }
}

export { createMargins }
