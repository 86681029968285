import React, { FC } from 'react'

interface SvgDropshadowProps {
  id: string
  color?: string
  blur?: number
  x?: number
  y?: number
}

const SvgDropshadow: FC<SvgDropshadowProps> = ({
  id,
  color = 'rgba(0,0,0,0.25)',
  blur = 2,
  x = 0,
  y = 0,
}) => {
  return (
    <defs>
      <filter
        id={id}
        x="-100%"
        y="-100%"
        width="300%"
        height="300%"
        filterUnits="userSpaceOnUse"
      >
        <feGaussianBlur in="SourceAlpha" stdDeviation={blur} />
        <feOffset dx={x} dy={y} result="offsetblur" />
        <feFlood floodColor={color} />
        <feComposite in2="offsetblur" operator="in" />
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
  )
}

export { SvgDropshadow }
