import {
  NoteNumber,
  ChordProperty,
} from '@roots-productions/chord_state_handler'
import { ChordStateHandlerModule } from '../chord-state-handler-module'

interface MaybeBareChordNote {
  chord_property?: ChordProperty
  relative_note_number?: NoteNumber
  absolute_note_number?: NoteNumber
}

interface BareChordNote {
  chord_property: ChordProperty
  relative_note_number: NoteNumber
  absolute_note_number: NoteNumber
}

const createBareChordNote = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (bareChordNote: MaybeBareChordNote): BareChordNote => {
  const {
    chord_property = chordStateHandlerModule.ChordProperty.Root,
    relative_note_number = chordStateHandlerModule.NoteNumber.Zero,
    absolute_note_number = chordStateHandlerModule.NoteNumber.Zero,
  } = bareChordNote

  return {
    chord_property,
    relative_note_number,
    absolute_note_number,
  }
}

export { createBareChordNote }
export type { MaybeBareChordNote, BareChordNote }
