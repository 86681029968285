interface HeightData {
  nutHeight: number
  fretCount: number
  fretDistance: number
  extraStringLength?: number
  verticalMargin?: number
}

const calcHeight = ({
  nutHeight = 0,
  fretCount = 3,
  fretDistance = 40,
  extraStringLength = 6,
  verticalMargin = 0,
}: HeightData) => {
  return (
    nutHeight +
    extraStringLength +
    verticalMargin +
    fretCount * fretDistance
  )
}

export { calcHeight }
