import React, { FC } from 'react'
import { midYOfFret } from '../../common'
import { Barre } from './barre'

interface FretboardBarre {
  strings: number[]
  fret: number
  color?: string
  text?: string
  textBackground?: string
  textColor?: string
}

interface BarresProps {
  barres: FretboardBarre[]
  fretYPositions: number[]
  fretDistance: number
  stringXPositions: number[]
  offsetToFirstFret: number
  radius?: number
  filterId?: string
}

const Barres: FC<BarresProps> = ({
  barres = [],
  fretYPositions = [],
  fretDistance = 40,
  stringXPositions = [],
  radius = 9,
  filterId = '',
  offsetToFirstFret = 0,
}) => {
  const fretToBarreY = midYOfFret(fretYPositions, fretDistance)
  const stringToBarreX = (string: number) =>
    stringXPositions[string] ?? 0

  const filter = filterId.length > 0 ? `url(#${filterId})` : ''

  return (
    <>
      {barres.map(
        (
          { fret, color, strings, text, textColor, textBackground },
          i
        ) => {
          const stringStart = strings[0] ?? 0
          const stringEnd = strings[1] ?? 0
          const xPositionStart = stringToBarreX(stringStart)
          const xPositionEnd = stringToBarreX(stringEnd)
          const yPosition = fretToBarreY(fret - 1 - offsetToFirstFret)

          return (
            <Barre
              key={`${fret}${stringStart ?? 0}${stringEnd ?? 0}${i}`}
              xPositionStart={xPositionStart}
              xPositionEnd={xPositionEnd}
              yPosition={yPosition}
              radius={radius}
              filter={filter}
              color={color}
              text={text}
              textColor={textColor}
              textBackground={textBackground}
            />
          )
        }
      )}
    </>
  )
}

export { Barres }
export type { FretboardBarre }
