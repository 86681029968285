import {
  fingerPlacementsFromChord,
  guitarFingerPlacementsFromChord,
} from './finger-placements-from-chord'
type FretboardModule = typeof import('@roots-productions/fretboard/esm/fretboard')

const storedModule: {
  isStored: boolean
  module: FretboardModule | null
} = {
  isStored: false,
  module: null,
}

const _fretboard = async () => {
  if (storedModule.isStored) {
    return storedModule.module as FretboardModule
  }

  const fretboardModule: FretboardModule = await import(
    '@roots-productions/fretboard'
  )

  storedModule.module = fretboardModule
  storedModule.isStored = true

  return storedModule.module as FretboardModule
}

const fretboard = async () => {
  const fretboardModule = await _fretboard()

  return {
    fingerPlacementsFromChord: fingerPlacementsFromChord(
      fretboardModule
    ),
    guitarFingerPlacementsFromChord: guitarFingerPlacementsFromChord(
      fretboardModule
    ),
  }
}

export { fretboard }
export type { FretboardModule }
