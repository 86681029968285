import { FC } from 'react'
import { Text, Container, HStack, Button } from '@chakra-ui/react'
import { GeneratorData } from '@chord-logic/chord-state-handler/model'

interface OtherNamesProps {
  chordVariations: GeneratorData[]
  onTagClick: (chordText: string) => void
}

const OtherNames: FC<OtherNamesProps> = ({
  chordVariations = [],
  onTagClick,
}) => {
  return (
    <Container>
      <Text>Also called:</Text>
      <HStack spacing={1}>
        {chordVariations.slice(0, 3).map(({ text }) => (
          <Button
            onClick={() => onTagClick(text)}
            key={text}
            borderRadius="full"
            variant="solid"
            fontSize="small"
            py="0"
            px="4"
            h="7"
            background="gray.500"
            color="white"
            _hover={{
              background: 'gray.300',
            }}
          >
            {text}
          </Button>
        ))}
      </HStack>
    </Container>
  )
}

export { OtherNames }
