import React, { FC } from 'react'
import { ChordNote } from '@src/chord-logic/chord-state-handler/model'
import { css } from '@emotion/react'

interface NotesAsTextProps {
  notes: ChordNote[]
}

const NotesAsText: FC<NotesAsTextProps> = ({ notes }) => {
  const style = css({
    '.note~.note::before': {
      content: "' '",
    },
  })

  return (
    <span css={style}>
      {notes.map((note) => (
        <span key={note.note_text} className="note">
          {note.note_text}
        </span>
      ))}
    </span>
  )
}

export { NotesAsText }
