import { Route, Switch } from 'wouter'
import { Home } from '@pages/home'
import { About } from '@pages/about'
import { Routes } from './routes'
import { BecomeARooter } from '@pages/become-a-rooter'
import { Documentation } from '@pages/documentation'
import { Contact } from '@pages/contact'

const RootRouter = () => {
  return (
    <Switch>
      <Route path={Routes.Home} component={Home} />
      <Route path={Routes.About} component={About} />
      <Route path={Routes.BecomeARooter} component={BecomeARooter} />
      <Route path={Routes.Documentation} component={Documentation} />
      <Route path={Routes.Contact} component={Contact} />
    </Switch>
  )
}

export { RootRouter }
