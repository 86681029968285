import React, { FC } from 'react'
import { midYOfFret } from '../../common'
import { Circle } from './circle'

interface FretboardCircle {
  string: number
  fret: number
  text?: string
  textBackground?: string
  textColor?: string
  color?: string
}

interface CirclesProps {
  circles: FretboardCircle[]
  fretYPositions: number[]
  fretDistance: number
  stringXPositions: number[]
  offsetToFirstFret: number
  radius?: number
  filterId?: string
}

const Circles: FC<CirclesProps> = ({
  circles = [],
  fretYPositions = [],
  stringXPositions = [],
  fretDistance = 40,
  radius = 9,
  filterId = '',
  offsetToFirstFret = 0,
}) => {
  const fretToCircleY = midYOfFret(fretYPositions, fretDistance)
  const stringToCircleX = (string: number) =>
    stringXPositions[string] ?? 0

  const filter = filterId.length > 0 ? `url(#${filterId})` : ''

  return (
    <>
      {circles.map(
        (
          { fret, string, color, text, textBackground, textColor },
          i
        ) => {
          const xPosition = stringToCircleX(string)
          const yPosition = fretToCircleY(
            fret - 1 - offsetToFirstFret
          )
          return (
            <Circle
              key={`${fret}${string}${i}`}
              xPosition={xPosition}
              yPosition={yPosition}
              radius={radius}
              color={color}
              filter={filter}
              text={text}
              textColor={textColor}
              textBackground={textBackground}
            />
          )
        }
      )}
    </>
  )
}

export { Circles }
export type { FretboardCircle }
