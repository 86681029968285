import React, { FC } from 'react'
import { Flex, Spacer, Switch, Text } from '@chakra-ui/react'

interface SwitcherProps {
  isChecked: boolean
  onChange: () => void
  text: string
}

const Switcher: FC<SwitcherProps> = ({
  isChecked,
  onChange,
  text,
}) => {
  return (
    <Flex w="100%" px={4}>
      <Text>{text}</Text>
      <Spacer />
      <Switch isChecked={isChecked} onChange={onChange} />
    </Flex>
  )
}

export { Switcher }
