import React, { useState } from 'react'
import { Center, Input, Container, VStack } from '@chakra-ui/react'
import {
  ExpandableSections,
  ExpandableSection,
} from '@components/expandable-sections'
import { useChordTool } from '@hooks'
import {
  NotesAsText,
  NotesPanel,
  OtherNames,
  FretboardSection,
  DiagramsPanel,
} from '@page-components/home'

const Home = () => {
  const [textInputValue, setTextInputValue] = useState('C')

  const {
    chordNotes,
    chordVariations,
    fingerPlacements,
    triggerTextUpdate,
    indexOfChosenFingerPlacement,
    setIndexOfChosenFingerPlacement,
    addNoteToChord,
    removeNoteFromChord,
  } = useChordTool()

  console.log('Current index:', indexOfChosenFingerPlacement)

  return (
    <Center py="12">
      <Container h="80%" overflow="auto" py="1px">
        <VStack spacing={8}>
          <VStack spacing={2} w="100%">
            <Input
              autoFocus
              textAlign="center"
              placeholder="Input your chord.."
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  triggerTextUpdate(textInputValue)
                }
              }}
              onChange={(event) =>
                setTextInputValue(event.target.value)
              }
              value={textInputValue}
            />
            <OtherNames
              chordVariations={chordVariations}
              onTagClick={(chordText) => {
                setTextInputValue(chordText)
                triggerTextUpdate(chordText)
              }}
            />
          </VStack>
          <FretboardSection
            fingerPlacement={
              fingerPlacements[indexOfChosenFingerPlacement]
            }
          />
          <ExpandableSections>
            <ExpandableSection title={`Diagrams`}>
              <DiagramsPanel
                fingerPlacements={fingerPlacements}
                indexOfChosenFingerPlacement={
                  indexOfChosenFingerPlacement
                }
                setIndexOfChosenFingerPlacement={
                  setIndexOfChosenFingerPlacement
                }
              />
            </ExpandableSection>
            <ExpandableSection
              title={
                <span>
                  Notes (<NotesAsText notes={chordNotes} />)
                </span>
              }
            >
              <NotesPanel
                chordNotes={chordNotes}
                onAddNote={addNoteToChord}
                onRemoveNote={removeNoteFromChord}
              />
            </ExpandableSection>
            <ExpandableSection title={`States`}>
              The states panel The states panel The states panel The
              states panel The states panel The states panel The
              states panel The states panelThe states panel
            </ExpandableSection>
          </ExpandableSections>
        </VStack>
      </Container>
    </Center>
  )
}

export { Home }
