import { Chord } from '@src/chord-logic/chord-state-handler/model'
import { FretboardModule } from '../fretboard-module'
import { createChordWithFingerPlacement } from '../model'

const guitarFingerPlacementsFromChord = (
  fretboardModule: FretboardModule
) => (chord: Chord) => {
  const { guitar_finger_placements_from_chord } = fretboardModule

  const res: any[] =
    JSON.parse(
      guitar_finger_placements_from_chord(JSON.stringify(chord))
    ) ?? []

  const chords = res.map((c) => createChordWithFingerPlacement()(c))

  return chords
}

export { guitarFingerPlacementsFromChord }
