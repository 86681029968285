import { FretboardString } from '@roots-productions/fretboard'
import { Chord } from '@src/chord-logic/chord-state-handler/model'
import { FretboardModule } from '../fretboard-module'
import { createChordWithFingerPlacement } from '../model'

const fingerPlacementsFromChord = (
  fretboardModule: FretboardModule
) => (instrument: FretboardString[], chord: Chord) => {
  const { finger_placements_from_chord } = fretboardModule

  const res: any[] = JSON.parse(
    finger_placements_from_chord(
      JSON.stringify(instrument),
      JSON.stringify(chord)
    )
  )

  const chords = res.map((c) => createChordWithFingerPlacement()(c))

  return chords
}

export { fingerPlacementsFromChord }
