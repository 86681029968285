import { TokenizedChord } from '@src/chord-logic/tokenize-chord'
import { ChordStateHandlerModule } from '../chord-state-handler-module'
import { createChord } from '../model'

const chordFromTokenizedChord = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (tokenizedChord: TokenizedChord) => {
  const { chord_from_tokenized } = chordStateHandlerModule

  return createChord(chordStateHandlerModule)(
    JSON.parse(chord_from_tokenized(JSON.stringify(tokenizedChord)))
  )
}

export { chordFromTokenizedChord }
