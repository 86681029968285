import { Chord, createChord } from '../model'
import { ChordStateHandlerModule } from '../chord-state-handler-module'

const updateChord = (
  chordStateHandlerModule: ChordStateHandlerModule
) => () => {
  const { add_note, remove_note } = chordStateHandlerModule

  return {
    addNote: (note: string, chord: Chord) =>
      createChord(chordStateHandlerModule)(
        JSON.parse(add_note(note, JSON.stringify(chord)))
      ),
    removeNote: (note: string, chord: Chord) =>
      createChord(chordStateHandlerModule)(
        JSON.parse(remove_note(note, JSON.stringify(chord)))
      ),
  }
}

export { updateChord }
