import React, { FC } from 'react'
import { Text } from '../text'
import { lastOr } from '../../common'

interface TextBelowProps {
  fretYPositions: number[]
  stringXPositions: number[]
  texts: string[]
  extraStringLength: number
  size?: number
}

const TextBelow: FC<TextBelowProps> = ({
  fretYPositions,
  stringXPositions,
  texts,
  extraStringLength,
  size = 8,
}) => {
  const lastY = lastOr(0)(fretYPositions)

  return (
    <>
      {texts.map((text, i) => {
        const xPosition = stringXPositions[i] ?? 0

        return i < stringXPositions.length ? (
          <Text
            key={`${xPosition}${text}`}
            yPosition={lastY + extraStringLength + size}
            xPosition={xPosition}
            size={size}
          >
            {text}
          </Text>
        ) : null
      })}
    </>
  )
}

export { TextBelow }
