import React, { ReactNode, FC } from 'react'

interface SvgProps {
  width: string | number
  height: string | number
  viewBoxWidth: number
  viewBoxHeight: number
  children: ReactNode
}

const Svg: FC<SvgProps> = ({
  children,
  width,
  height,
  viewBoxWidth,
  viewBoxHeight,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
    </svg>
  )
}

export { Svg }
