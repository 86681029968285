import { Button } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface BlackKeyProps {
  children: ReactNode
  onClick?: () => void
}

const BlackKey: FC<BlackKeyProps> = ({
  children,
  onClick = () => {},
}) => {
  return (
    <Button
      flex="2"
      p="0"
      bg="gray.900"
      color="white"
      mx="2px"
      _hover={{ background: 'gray.600' }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export { BlackKey }
