import { ChordStateHandlerModule } from '../chord-state-handler-module'
import { Chord, createChord } from '../model'

interface MaybeGeneratorData {
  chord?: Chord
  keywords?: string[]
  text?: string
  text_sections?: [string, string, string]
}

interface GeneratorData {
  chord: Chord
  keywords: string[]
  text: string
  text_sections: [string, string, string]
}

const createGeneratorData = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (generatorData: MaybeGeneratorData = {}): GeneratorData => {
  const {
    chord = createChord(chordStateHandlerModule)({}),
    keywords = [],
    text = '',
    text_sections = ['', '', ''],
  } = generatorData

  return {
    chord,
    keywords,
    text,
    text_sections,
  }
}

export { createGeneratorData }
export type { MaybeGeneratorData, GeneratorData }
