import { Chord } from '../model'
import { ChordStateHandlerModule } from '../chord-state-handler-module'

const isAssumedChordHalfStepAlignmentSharp = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chord: Chord) => {
  const {
    is_assumed_chord_half_step_alignment_sharp,
  } = chordStateHandlerModule

  return is_assumed_chord_half_step_alignment_sharp(
    JSON.stringify(chord)
  )
}

export { isAssumedChordHalfStepAlignmentSharp }
