import { chordStateHandler } from '@chord-logic/chord-state-handler'
import {
  Chord,
  ChordNote,
  GeneratorData,
} from '@chord-logic/chord-state-handler/model'
import { fretboard } from '@chord-logic/fretboard'
import { ChordWithFingerPlacement } from '@chord-logic/fretboard/model'
import { tokenizeChord } from '@chord-logic/tokenize-chord'
import { useCallback, useEffect, useState } from 'react'

const useChordTool = (chordText = 'C') => {
  const [chordState, setChordState] = useState<Chord | null>(null)
  const [fingerPlacements, setFingerPlacements] = useState<
    ChordWithFingerPlacement[]
  >([])
  const [chordNotes, setChordNotes] = useState<ChordNote[]>([])

  const [chordVariations, setChordVariations] = useState<
    GeneratorData[]
  >([])

  const [
    indexOfChosenFingerPlacement,
    setIndexOfChosenFingerPlacement,
  ] = useState(0)

  const updateChordState = async (_chordState: Chord) => {
    const { guitarFingerPlacementsFromChord } = await fretboard()
    const {
      noteInformationFromChord,
      chordVariationsTextsFromChord,
      isAssumedChordHalfStepAlignmentSharp,
    } = await chordStateHandler()

    setChordState(_chordState)

    const _fingerPlacements = await guitarFingerPlacementsFromChord(
      _chordState
    )
    const _chordVariations = chordVariationsTextsFromChord(
      _chordState
    )

    setChordVariations(_chordVariations)
    setChordNotes(
      noteInformationFromChord(
        _chordState,
        isAssumedChordHalfStepAlignmentSharp(_chordState)
      )
    )
    setIndexOfChosenFingerPlacement(0)
    setFingerPlacements(_fingerPlacements)
  }

  const triggerTextUpdate = useCallback(
    async (_chordText: string) => {
      const { chordFromTokenizedChord } = await chordStateHandler()

      const tokenized = await tokenizeChord(_chordText)

      const _chordState = chordFromTokenizedChord(tokenized)
      await updateChordState(_chordState)
    },
    []
  )

  const addNoteToChord = async (note: string) => {
    const { updateChord } = await chordStateHandler()

    if (chordState) {
      await updateChordState(updateChord().addNote(note, chordState))
    }
  }

  const removeNoteFromChord = async (note: string) => {
    const { updateChord } = await chordStateHandler()

    if (chordState) {
      await updateChordState(
        updateChord().removeNote(note, chordState)
      )
    }
  }

  useEffect(() => {
    triggerTextUpdate(chordText)
  }, [chordText, triggerTextUpdate])

  return {
    fingerPlacements,
    chordNotes,
    chordVariations,
    triggerTextUpdate,
    indexOfChosenFingerPlacement,
    setIndexOfChosenFingerPlacement,
    setChordState,
    addNoteToChord,
    removeNoteFromChord,
  }
}

export { useChordTool }
