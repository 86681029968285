import React, { FC } from 'react'

interface FretNumberProps {
  number: number
  firstStringX: number
  firstFretY: number
  fretDistance: number
  color?: string
  circleRadius?: number
}

const FretNumber: FC<FretNumberProps> = ({
  number = 1,
  firstStringX = 0,
  firstFretY = 0,
  fretDistance = 0,
  color = 'hsl(0, 50%, 15%)',
  circleRadius = 8,
}: FretNumberProps) => {
  return (
    <text
      x={firstStringX - circleRadius - 2}
      y={firstFretY + fretDistance / 2}
      style={{
        font: `${14}px sans-serif`,
        fill: color,
        textAnchor: 'end',
        alignmentBaseline: 'middle',
        dominantBaseline: 'middle',
      }}
    >
      {number}
    </text>
  )
}

export { FretNumber }
