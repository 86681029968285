import { Button } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'

interface WhiteKeyProps {
  children: ReactNode
  onClick?: () => void
}

const WhiteKey: FC<WhiteKeyProps> = ({
  children,
  onClick = () => {},
}) => {
  return (
    <Button
      flex="1"
      p="0"
      bg="white"
      color="gray.900"
      mx="2px"
      border="2px"
      borderColor="gray.900"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export { WhiteKey }
