import {
  Fifth,
  Third,
  NoteNumber,
  Sixth,
  Seventh,
  Ninth,
  Eleventh,
} from '@roots-productions/chord_state_handler'

import { ChordStateHandlerModule } from '../chord-state-handler-module'

interface MaybeChord {
  root?: NoteNumber
  bass?: NoteNumber
  third?: Third
  fifth?: Fifth
  sixth?: Sixth
  seventh?: Seventh
  ninth?: Ninth
  eleventh?: Eleventh
  additional?: NoteNumber[]
}

interface Chord {
  root: NoteNumber
  bass: NoteNumber
  third: Third
  fifth: Fifth
  sixth: Sixth
  seventh: Seventh
  ninth: Ninth
  eleventh: Eleventh
  additional: NoteNumber[]
}

const createChord = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chord: MaybeChord): Chord => {
  const {
    root = chordStateHandlerModule.NoteNumber.Zero,
    bass = chordStateHandlerModule.NoteNumber.Zero,
    third = chordStateHandlerModule.Third.Four,
    fifth = chordStateHandlerModule.Fifth.Seven,
    sixth = chordStateHandlerModule.Sixth.Unset,
    seventh = chordStateHandlerModule.Seventh.Unset,
    ninth = chordStateHandlerModule.Ninth.Unset,
    eleventh = chordStateHandlerModule.Eleventh.Unset,
    additional = [],
  } = chord

  return {
    root,
    bass,
    third,
    fifth,
    sixth,
    seventh,
    ninth,
    eleventh,
    additional,
  }
}

export { createChord }
export type { MaybeChord, Chord }
