const takeFirstAndLastOrDefault = <T>(
  list: T[],
  first: T,
  last: T
): T[] => {
  return [
    list[0] ?? first,
    list.length > 0 ? list[list.length - 1] : last,
  ]
}

export { takeFirstAndLastOrDefault }
