const colors = {
  gray: {
    '50': '#F2F3F3',
    '100': '#D9DDDD',
    '200': '#C1C7C6',
    '300': '#A9B2B0',
    '400': '#919C9A',
    '500': '#798684',
    '600': '#616B6A',
    '700': '#49504F',
    '800': '#303635',
    '900': '#181B1A',
  },
  red: {
    '50': '#FDEDE8',
    '100': '#F9CBBE',
    '200': '#F5AA94',
    '300': '#F1896A',
    '400': '#ED6840',
    '500': '#E94717',
    '600': '#BA3912',
    '700': '#8B2A0E',
    '800': '#5D1C09',
    '900': '#2F0E04',
  },
  orange: {
    '50': '#FDF2E8',
    '100': '#F9D9BE',
    '200': '#F5C194',
    '300': '#F1A96A',
    '400': '#EC9140',
    '500': '#E87917',
    '600': '#BA6012',
    '700': '#8B480E',
    '800': '#5D3009',
    '900': '#2E1805',
  },
  green: {
    '50': '#EBF9F2',
    '100': '#C7EFDB',
    '200': '#A4E5C3',
    '300': '#80DBAC',
    '400': '#5CD195',
    '500': '#39C67D',
    '600': '#2D9F64',
    '700': '#22774B',
    '800': '#174F32',
    '900': '#0B2819',
  },
  blue: {
    '50': '#EAF2FB',
    '100': '#C3DBF3',
    '200': '#9DC5EC',
    '300': '#76AEE5',
    '400': '#5097DD',
    '500': '#2980D6',
    '600': '#2166AB',
    '700': '#194D80',
    '800': '#103356',
    '900': '#081A2B',
  },
  cyan: {
    '50': '#E9F7FC',
    '100': '#C1EAF6',
    '200': '#99DCF0',
    '300': '#71CFEA',
    '400': '#49C1E4',
    '500': '#21B4DE',
    '600': '#1A90B2',
    '700': '#146C85',
    '800': '#0D4859',
    '900': '#07242C',
  },
  purple: {
    '50': '#F1EEF7',
    '100': '#D8CEE8',
    '200': '#BFAFDA',
    '300': '#A690CB',
    '400': '#8D71BC',
    '500': '#7351AE',
    '600': '#5C418B',
    '700': '#453168',
    '800': '#2E2145',
    '900': '#171023',
  },
  pink: {
    '50': '#FBEAF4',
    '100': '#F3C4DF',
    '200': '#EB9ECB',
    '300': '#E377B6',
    '400': '#DC51A2',
    '500': '#D42B8D',
    '600': '#A92371',
    '700': '#7F1A55',
    '800': '#551138',
    '900': '#2A091C',
  },
}

export { colors }
