import React, { FC } from 'react'
import { Container, VStack, Text, useToast } from '@chakra-ui/react'
import { ChordNote } from '@chord-logic/chord-state-handler/model'
import { NoteRow } from './note-row'
import { PianoNotePicker } from '@pure-components/piano-note-picker'

interface NotesPanelProps {
  chordNotes: ChordNote[]
  onAddNote: (note: string) => Promise<void>
  onRemoveNote: (note: string) => Promise<void>
}

const NotesPanel: FC<NotesPanelProps> = ({
  chordNotes = [],
  onAddNote,
  onRemoveNote,
}) => {
  const toast = useToast()

  return (
    <Container w="100%">
      <VStack w="100%" spacing={6} py={3}>
        <VStack w="100%">
          {chordNotes.map((chordNote) => (
            <NoteRow
              key={chordNote.note_text}
              note={chordNote}
              onClick={(n) => {
                if (chordNotes.length < 4) {
                  toast({
                    title: 'Unable to remove note',
                    description:
                      'A chord consists of at least three notes. Add a note before removing another.',
                    status: 'error',
                    duration: 6000,
                    isClosable: true,
                  })
                } else {
                  onRemoveNote(n)
                }
              }}
            />
          ))}
        </VStack>
        <VStack w="100%" spacing={4}>
          <Text w="100%">Add a note:</Text>
          <PianoNotePicker onClick={(n) => onAddNote(n)} />
        </VStack>
      </VStack>
    </Container>
  )
}

export { NotesPanel }
