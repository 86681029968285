import React from 'react'
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react'
import { HamburgerIcon } from '@chakra-ui/icons'
import { Routes } from '@components/router'
import { NavbarLink } from './navbar-link'

const NavBarLinks = ({
  direction = 'horizontal',
}: {
  direction?: 'horizontal' | 'vertical'
}) => {
  return (
    <>
      <NavbarLink
        href={Routes.Home}
        text="Home"
        direction={direction}
      />
      <NavbarLink
        href={Routes.Documentation}
        text="Docs"
        direction={direction}
      />
      <NavbarLink
        href={Routes.BecomeARooter}
        text="Become a Rooter"
        direction={direction}
      />
      <NavbarLink
        href={Routes.Contact}
        text="Contact"
        direction={direction}
      />
      <NavbarLink
        href={Routes.About}
        text="About"
        direction={direction}
      />
    </>
  )
}

const NavBar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const hamburgerDisplay = useBreakpointValue({
    base: 'block',
    md: 'none',
  })
  const desktopDisplay = useBreakpointValue({
    base: 'none',
    md: 'flex',
  })

  return (
    <Box bg="gray.900" w="100%" h="12" pos="relative">
      <Flex w="100%" h="100%" display={desktopDisplay}>
        <NavBarLinks />
      </Flex>
      <Button
        onClick={onOpen}
        pos="absolute"
        right="6"
        top="50%"
        transform="translateY(-50%)"
        display={hamburgerDisplay}
        variant="ghost"
        _hover={{
          background: 'gray.700',
        }}
      >
        <HamburgerIcon color="white" />
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent background="gray.900">
            <DrawerHeader>Pages</DrawerHeader>
            <DrawerCloseButton />
            <DrawerBody>
              <NavBarLinks direction="vertical" />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  )
}

export { NavBar }
