import React, { FC } from 'react'
import { Link, useRoute } from 'wouter'
import { Routes } from '@components/router'
import { Box } from '@chakra-ui/react'

interface NavbarLinkProps {
  href: Routes
  text: string
  direction?: 'vertical' | 'horizontal'
}

const NavbarLink: FC<NavbarLinkProps> = ({
  href,
  text,
  direction = 'horizontal',
}) => {
  const [isActive] = useRoute(href)

  return (
    <Link href={href}>
      <Box
        as="a"
        color={isActive ? 'white' : 'gray.400'}
        h={direction === 'horizontal' ? '100%' : '10'}
        d="flex"
        alignItems="center"
        justifyContent="center"
        flex="1"
      >
        {text}
      </Box>
    </Link>
  )
}

export { NavbarLink }
