import { createTokenizedChord } from './tokenized-chord'

const storedFunction = {
  isStored: false,
  fn: (chord: string) => createTokenizedChord({}),
}

const tokenizeChord = async (chord: string) => {
  if (storedFunction.isStored) {
    return storedFunction.fn(chord)
  }

  const { tokenize_chord } = await import(
    '@roots-productions/chord_tokenizer'
  )

  storedFunction.fn = (_chord: string) =>
    createTokenizedChord(JSON.parse(tokenize_chord(_chord)))
  storedFunction.isStored = true

  return storedFunction.fn(chord)
}

export { tokenizeChord }
