import React, { FC, ReactNode } from 'react'
import { Button } from '@chakra-ui/react'

interface DiagramBoxProps {
  children: ReactNode
  isPicked?: boolean
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
  style?: React.CSSProperties
}

const DiagramBox: FC<DiagramBoxProps> = ({
  children,
  isPicked = false,
  onClick = () => {},
  style,
}) => {
  return (
    <Button
      style={style}
      w={79}
      minW={79}
      maxW={79}
      h="90% !important"
      mt={1}
      p={2}
      display="inline"
      bg="white"
      border={isPicked ? '2px' : '0px'}
      borderColor="blue.400"
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export { DiagramBox }
