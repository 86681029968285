import type { FretboardConf } from './types'

interface FretboardStringInput {
  strokeWidth?: number
  color?: string
}

interface FretboardConfInput {
  strings?: FretboardStringInput[]
  fretColor?: string
  stringDistance?: number
  fretDistance?: number
  minFretCount?: number
  nutHeight?: number
  nutColor?: string
  circleRadius?: number
  stringColor?: string
}

const createFretboardConf = ({
  strings,
  fretColor,
  stringDistance,
  fretDistance,
  minFretCount,
  nutHeight,
  nutColor,
  circleRadius,
  stringColor,
}: FretboardConfInput): FretboardConf => {
  const _strings = strings ?? []

  return {
    strings: _strings.map((_string) => ({
      color: stringColor ?? 'hsl(0,0%,15%)',
      strokeWidth: 1,
      ..._string,
    })),
    fretColor: fretColor ?? 'hsl(0,0%,50%)',
    stringDistance: stringDistance ?? 19.8,
    fretDistance: fretDistance ?? 40,
    minFretCount: minFretCount ?? 3,
    nutHeight: nutHeight ?? 8,
    nutColor: nutColor ?? 'hsl(0,0%,15%)',
    circleRadius: circleRadius ?? 8,
  }
}

export { createFretboardConf }
