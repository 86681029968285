import {
  NoteNumber,
  NoteVariant,
  ChordProperty,
  NoteFunction,
} from '@roots-productions/chord_state_handler'
import { ChordStateHandlerModule } from '../chord-state-handler-module'

interface MaybeChordNote {
  relative_note_number?: NoteNumber
  absolute_note_number?: NoteNumber
  note_text?: string
  variant?: NoteVariant
  chord_property?: ChordProperty[]
  note_function?: NoteFunction[]
  note_function_readable?: string[]
}

interface ChordNote {
  relative_note_number: NoteNumber
  absolute_note_number: NoteNumber
  note_text: string
  variant: NoteVariant
  chord_property: ChordProperty[]
  note_function: NoteFunction[]
  note_function_readable: string[]
}

const createChordNote = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chordNote: MaybeChordNote = {}): ChordNote => {
  const {
    relative_note_number = chordStateHandlerModule.NoteNumber.Zero,
    absolute_note_number = chordStateHandlerModule.NoteNumber.Zero,
    note_text = 'C',
    variant = chordStateHandlerModule.NoteVariant.One,
    chord_property = [chordStateHandlerModule.ChordProperty.Root],
    note_function = [chordStateHandlerModule.NoteFunction.Root],
    note_function_readable = ['root'],
  } = chordNote

  return {
    relative_note_number,
    absolute_note_number,
    note_text,
    variant,
    chord_property,
    note_function,
    note_function_readable,
  }
}

export { createChordNote }
export type { MaybeChordNote, ChordNote }
