const calcFretCount = (
  minFretCount: number = 3,
  firstFret: number = 1,
  lastFret: number = 3,
) => {
  const chordStretch = lastFret - firstFret + 1
  return chordStretch > minFretCount ? chordStretch : minFretCount
}

export { calcFretCount }
