import React, { FC, ReactNode } from 'react'
import { Accordion } from '@chakra-ui/react'

interface ExpandableSectionsProps {
  children?: ReactNode
}

const ExpandableSections: FC<ExpandableSectionsProps> = ({
  children = <></>,
}) => {
  return (
    <Accordion allowMultiple w="100%">
      {children}
    </Accordion>
  )
}

export { ExpandableSections }
