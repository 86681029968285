import React, { FC } from 'react'
import { HStack } from '@chakra-ui/react'
import { FixedSizeList } from 'react-window'
import { ChordWithFingerPlacement } from '@chord-logic/fretboard/model'
import { DiagramBox } from './diagram-box'
import { FretboardChord } from '../fretboard-chord'
import AutoSizer from 'react-virtualized-auto-sizer'
import { css } from '@emotion/react'

interface DiagramsPanelProps {
  fingerPlacements: ChordWithFingerPlacement[]
  indexOfChosenFingerPlacement: number
  setIndexOfChosenFingerPlacement: (i: number) => void
}

const DiagramsPanel: FC<DiagramsPanelProps> = ({
  fingerPlacements,
  indexOfChosenFingerPlacement,
  setIndexOfChosenFingerPlacement,
}) => {
  // Of some reason the height gets set to 0 in AutoSizer.
  // This style is to override that.
  const styles = css({
    '&>div:first-of-type': {
      height: 'unset !important',
    },
  })

  return (
    <HStack h={120} py={2} css={styles}>
      <AutoSizer>
        {({ height, width }) => (
          <FixedSizeList
            layout="horizontal"
            height={height}
            itemCount={fingerPlacements.length}
            itemSize={100}
            width={width}
          >
            {({ index, style }) => (
              <DiagramBox
                style={{
                  ...style,
                  marginLeft: index === 0 ? 3 : 0,
                }}
                isPicked={index === indexOfChosenFingerPlacement}
                onClick={() => setIndexOfChosenFingerPlacement(index)}
              >
                <FretboardChord
                  fingerPlacement={fingerPlacements[index]}
                />
              </DiagramBox>
            )}
          </FixedSizeList>
        )}
      </AutoSizer>
    </HStack>
  )
}

export { DiagramsPanel }
