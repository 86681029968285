import { ChordStateHandlerModule } from '../chord-state-handler-module'
import { createChord, Chord } from '../model'

const createChords = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (data: any[]) => {
  return data.map(createChord(chordStateHandlerModule))
}

const chordVariationsFromChord = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chord: Chord) => {
  const { chord_variations_from_chord } = chordStateHandlerModule

  return createChords(chordStateHandlerModule)(
    JSON.parse(chord_variations_from_chord(JSON.stringify(chord)))
  )
}

export { chordVariationsFromChord }
