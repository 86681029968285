import { ChordStateHandlerModule } from '../chord-state-handler-module'
import { Chord, createGeneratorData } from '../model'

const createGeneratorDataList = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (data: any[]) => {
  return data.map(createGeneratorData(chordStateHandlerModule))
}

const chordVariationsTextsFromChord = (
  chordStateHandlerModule: ChordStateHandlerModule
) => (chord: Chord) => {
  const {
    chord_variations_texts_from_chord,
  } = chordStateHandlerModule

  return createGeneratorDataList(chordStateHandlerModule)(
    JSON.parse(
      chord_variations_texts_from_chord(JSON.stringify(chord))
    )
  )
}

export { chordVariationsTextsFromChord }
